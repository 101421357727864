import {useMemo, useState} from 'react';
import {PlusCircleOutlined} from '@ant-design/icons';

import {Button, PageHeader, Table} from '~components';
import {useGetData, usePermission} from '~hooks';
import {AllPermission} from '~variables';
import {TaskGroup, TaskGroupFilterParams} from '~models';
import {getTaskGroupsThunk} from '~reducers';

import {AddTaskGroupModal, TaskGroupFilter} from './components';
import {getColumns} from './utils';
import './styles.scss';

const TaskGroupPage = () => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const {user, allowedPermissions} = usePermission();

  const {
    isLoading,
    data: taskGroups,
    pagination,
    searchParams,
    dispatch,
    setSearchParams,
    getData: getTaskGroups,
  } = useGetData<TaskGroup, TaskGroupFilterParams>(getTaskGroupsThunk);

  const columns = useMemo(() => {
    return getColumns({
      user,
      allowedPermissions,
      pagination,
      searchTerm: searchParams.search,
      taskGroupsLength: taskGroups.length,
      dispatch,
      setSearchParams,
      getTaskGroups,
    });
  }, [
    user,
    allowedPermissions,
    pagination,
    searchParams.search,
    taskGroups.length,
    dispatch,
    setSearchParams,
    getTaskGroups,
  ]);

  return (
    <div className="task-group">
      <PageHeader
        title="DANH SÁCH NHÓM LỖI THAM CHIẾU"
        actions={
          allowedPermissions.includes(AllPermission.Task_groups__Create) && (
            <Button
              type="primary"
              size="large"
              icon={<PlusCircleOutlined />}
              onClick={() => setIsModalOpen(true)}>
              Thêm mới
            </Button>
          )
        }
      />

      <TaskGroupFilter searchParams={searchParams} setSearchParams={setSearchParams} />

      <Table
        className="task-group__table"
        emptyText="Nhóm lỗi tham chiếu trống"
        columns={columns}
        dataSource={taskGroups}
        loading={isLoading}
        pagination={{
          from: pagination.from,
          to: pagination.to,
          total: pagination.total,
          current: pagination.current_page,
          pageSize: pagination.per_page,
          onChange: current_page => setSearchParams({current_page}),
          onShowSizeChange: (_, per_page) => setSearchParams({per_page, current_page: 1}),
        }}
      />

      <AddTaskGroupModal
        open={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        getTaskGroups={getTaskGroups}
      />
    </div>
  );
};

export default TaskGroupPage;
