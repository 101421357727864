import { TaskGroup } from '~models';

import { axiosClient } from './axios-client';
import { ListResponse, SuccessResponse } from './service-models';

interface GetTaskGroupsAPIParams {
  search?: string;
  page?: number;
  per_page?: number;
}

export const addTaskGroupAPI = (name: string) => {
  return axiosClient.post<SuccessResponse>('/task_groups', { name });
};

export const getTaskGroupsAPI = (params: GetTaskGroupsAPIParams) => {
  return axiosClient.get<ListResponse<TaskGroup>>('/task_groups', { params });
};

export const deleteTaskGroupAPI = (id: string) => {
  return axiosClient.delete<SuccessResponse>(`task_groups/${id}`);
};
