import {notification} from 'antd';
import {HttpStatusCode} from 'axios';
import {createAsyncThunk} from '@reduxjs/toolkit';

import {getSearchParamsWindow, isNullUndefined} from '~utils';
import {Status, TaskFilterParams} from '~models';
import {addTaskAPI, deleteTaskAPI, editTaskAPI, getTasksAPI} from '~services';

import {AddTaskPayload, DeleteTaskPayload, EditTaskPayload, GetTasksPayload} from './action-models';

export const addTaskThunk = createAsyncThunk(
  'TASK_SLICE/ADD_TASK_THUNK',
  async ({payload, callback}: AddTaskPayload) => {
    try {
      const response = await addTaskAPI({
        name: payload.name,
        active: payload.active,
        groups: payload.taskGroups.map(t => t.id),
        parent: payload.parentTask?.id,
      });

      if (response.status === HttpStatusCode.Ok) {
        notification.success({
          message: 'Thêm mới thành công',
          description: 'Thêm mới nội dung kiểm soát thành công.',
        });

        callback();
      }
    } catch (error: any) {
      notification.error({
        message: 'Thêm mới thất bại',
        description: error.response.data.message,
      });
    }
  },
);

export const editTaskThunk = createAsyncThunk(
  'TASK_SLICE/EDIT_TASK_THUNK',
  async ({payload, callback}: EditTaskPayload) => {
    try {
      const response = await editTaskAPI({
        id: payload.taskId,
        name: payload.name,
        active: payload.active,
        groups: payload.taskGroups.map(tg => tg.id),
      });

      if (response.status === HttpStatusCode.Ok) {
        notification.success({
          message: 'Cập nhật thành công',
          description: 'Cập nhật nội dung kiểm soát thành công.',
        });

        callback();
      }
    } catch (error: any) {
      notification.error({
        message: 'Cập nhật thất bại',
        description: error.response.data.message,
      });
    }
  },
);

export const getTasksThunk = createAsyncThunk(
  'TASK_SLICE/GET_TASKS_THUNK',
  async ({setIsLoading, callback}: GetTasksPayload) => {
    try {
      setIsLoading(true);
      const searchParams = getSearchParamsWindow<TaskFilterParams>();

      const response = await getTasksAPI({
        page: searchParams.current_page,
        per_page: searchParams.per_page,
        search: searchParams.search,
        root_active: isNullUndefined(searchParams.root_active)
          ? undefined
          : searchParams.root_active === Status.ACTIVE,
      });

      if (response.status === HttpStatusCode.Ok) {
        callback(response.data.data);
      }
    } catch (error: any) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  },
);

export const deleteTaskThunk = createAsyncThunk(
  'TASK_SLICE/DELETE_TASK_THUNK',
  async ({id, callback}: DeleteTaskPayload) => {
    try {
      const response = await deleteTaskAPI(id);

      if (response.status === HttpStatusCode.Ok) {
        notification.success({
          message: 'Xoá thành công',
          description: 'Xoá nội dung kiểm soát thành công.',
        });

        callback();
      }
    } catch (error: any) {
      notification.error({
        message: 'Xoá thất bại',
        description: error.response.data.message,
      });
    }
  },
);
