import {memo, useEffect, useState} from 'react';
import {DefaultOptionType} from 'antd/es/select';

import {Select} from '~components';
import {Department, TemplateFilterParams} from '~models';
import {getDepartmentByIdAPI, getDepartmentsAPI} from '~services';
import {useGetDataSelect} from '~hooks';
import {convertToOption} from '~utils';

interface Props {
  selectedDepartmentId?: string;
  setSearchParams: (values: TemplateFilterParams) => void;
}

const SelectDepartment = ({selectedDepartmentId, setSearchParams}: Props) => {
  const [selectedDepartment, setSelectedDepartment] = useState<DefaultOptionType>();

  const {
    dataOptions: departmentOptions,
    pagination,
    setSearchTerm,
    setPagination,
    handleEndReached,
    handleSearchChange,
  } = useGetDataSelect<Department>(getDepartmentsAPI);

  const handleSelect = (department: DefaultOptionType) => {
    setSearchParams({department: String(department.value)});
    setSelectedDepartment(department);
  };

  const handleClear = () => {
    setSearchTerm('');
    setPagination({...pagination, current_page: 1});
    setSelectedDepartment(undefined);
    setSearchParams({department: ''});
  };

  useEffect(() => {
    if (selectedDepartmentId) {
      getDepartmentByIdAPI(selectedDepartmentId).then(response => {
        const data = response.data.data;
        setSelectedDepartment(convertToOption(data));
      });
    }
  }, [selectedDepartmentId]);

  return (
    <Select
      className="field"
      placeholder="---Chọn phòng ban---"
      label="Phòng ban"
      horizontal
      showSearch
      allowClear
      labelInValue
      filterOption={false}
      value={selectedDepartment}
      options={departmentOptions}
      onSearch={handleSearchChange}
      onSelect={handleSelect}
      onEndReached={handleEndReached}
      onClear={handleClear}
    />
  );
};

export default memo(SelectDepartment);
