import { Input } from 'antd';
import { TextAreaProps } from 'antd/es/input';

import Text from '../Text';

import './styles.scss';

interface Props extends TextAreaProps {
  className?: string;
  label?: string;
  errorMsg?: string;
  required?: boolean;
}

const TextArea = ({ className, label, errorMsg, required, ...props }: Props) => {
  return (
    <div className={`textarea ${className}`}>
      {label && (
        <div className="textarea__label">
          <Text className="textarea__label-text">{label}</Text>
          {required && <Text className="textarea__label-required">*</Text>}
        </div>
      )}
      <Input.TextArea className="textarea__main" status={errorMsg && 'error'} {...props} />
      {errorMsg && <Text className="textarea__error">{errorMsg}</Text>}
    </div>
  );
};

export default TextArea;
