import {Form, ModalProps} from 'antd';

import {Input, Modal} from '~components';
import {addTaskGroupThunk} from '~reducers';
import {useAppDispatch} from '~store';

import {AddTaskGroupForm, initialValues, nameRules} from '../../utils';
import './styles.scss';

interface Props extends ModalProps {
  setIsModalOpen: (value: boolean) => void;
  getTaskGroups: () => void;
}

const AddTaskGroupModal = (props: Props) => {
  const dispatch = useAppDispatch();
  const [form] = Form.useForm<AddTaskGroupForm>();

  const {getTaskGroups, setIsModalOpen} = props;
  const {submit, resetFields} = form;

  const handleAdd = ({name}: AddTaskGroupForm) => {
    dispatch(
      addTaskGroupThunk({
        name,
        callback: () => {
          setIsModalOpen(false);
          getTaskGroups();
        },
      }),
    );
  };

  return (
    <Modal
      width={700}
      className="add-task-group-modal"
      title="Tạo mới nhóm lỗi tham chiếu"
      cancelText="Huỷ"
      okText="Thêm mới"
      onOk={submit}
      onCancel={() => setIsModalOpen(false)}
      afterClose={resetFields}
      {...props}>
      <Form form={form} onFinish={handleAdd} initialValues={initialValues}>
        <Form.Item name="name" rules={nameRules}>
          <Input label="Tên nhóm lỗi tham chiếu" placeholder="Tên nhóm lỗi tham chiếu" required />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default AddTaskGroupModal;
