import {TreeDataType} from '~models';

import {cloneDeep} from './common';
import {getLowerCaseNonAccentVietnamese} from './non-accent-vietnamese';

export const getSelectedTrees = <T extends any[]>(
  trees: T,
  selectedIds: string[],
  firstLevel: number = 1,
): T => {
  const newTrees = firstLevel === 1 ? cloneDeep<T>(trees) : trees;

  return newTrees.filter(tree => {
    if (selectedIds.includes(tree.id)) return true;

    if (tree.children.length) {
      const children = getSelectedTrees(tree.children, selectedIds, firstLevel + 1);

      if (!children.length) return false;

      tree.children = children;
      return true;
    }

    return false;
  }) as T;
};

export const getRootNodeTreeId = <T extends R[], R extends TreeDataType>(
  trees: T,
  id: string,
): R | undefined => {
  return trees.find(tree => tree.id === id || getRootNodeTreeId(tree.children, id));
};

export const getAllIdExpandedTree = <T extends any[], R extends string[]>(trees: T): R => {
  return trees.reduce((ids, tree) => {
    const newIds = [...ids, tree.id];
    if (tree.children.length) {
      newIds.push(...getAllIdExpandedTree(tree.children));
    }
    return newIds;
  }, []);
};

export const getSelectedLeafIds = <T extends any[], R extends string[]>(trees: T): R => {
  return trees.reduce((ids, tree) => {
    if (tree.children.length) {
      return [...ids, ...getSelectedLeafIds(tree.children)];
    }
    return [...ids, tree.id];
  }, []);
};

export const mergeTrees = <T extends any[]>(trees: T, newTrees: T): T => {
  return [
    ...trees.map(tree => {
      const existedTree = newTrees.find(newTree => newTree.id === tree.id);
      return existedTree
        ? {...tree, children: mergeTrees(tree.children, existedTree.children)}
        : tree;
    }),
    ...newTrees.filter(newTree => !trees.some(tree => tree.id === newTree.id)),
  ] as T;
};

export const getSelectedTreesSelectChange = <T extends any[]>(
  selectedTrees: T,
  newTrees: T,
  selected: boolean,
): T => {
  const selectedIdsInNewTrees = getSelectedLeafIds(newTrees);

  const {remainSelectedTrees, existedTrees} = selectedTrees.reduce(
    (result, selectedTree) => {
      if (newTrees.some(newTree => newTree.id === selectedTree.id)) {
        result.existedTrees.push(selectedTree);
      } else {
        result.remainSelectedTrees.push(selectedTree);
      }
      return result;
    },
    {
      remainSelectedTrees: [],
      existedTrees: [],
    },
  );
  const selectedIdsInExistedTrees = getSelectedLeafIds(existedTrees);

  let newSelectedTrees = remainSelectedTrees;

  if (existedTrees.length) {
    let mergedSelectedIds = [];

    if (selected) {
      mergedSelectedIds = Array.from(
        new Set([...selectedIdsInExistedTrees, ...selectedIdsInNewTrees]),
      );
    } else {
      mergedSelectedIds = selectedIdsInExistedTrees.filter(
        id => !selectedIdsInNewTrees.includes(id),
      );
    }

    if (mergedSelectedIds.length) {
      const mergedTrees = mergeTrees(existedTrees, newTrees);
      const mergedNewTrees = getSelectedTrees(mergedTrees, mergedSelectedIds);
      newSelectedTrees = [...remainSelectedTrees, ...mergedNewTrees];
    }
  } else {
    newSelectedTrees = [...selectedTrees, ...newTrees];
  }

  return newSelectedTrees;
};

export const sortTreesCreatedAtDESC = <T extends any[]>(arr: T) => {
  arr.sort((item1, item2) => {
    if (item1.children.length) sortTreesCreatedAtDESC(item1.children);

    if (item2.children.length) sortTreesCreatedAtDESC(item2.children);

    const date1 = new Date(item1.created_at);
    const date2 = new Date(item2.created_at);
    return date2.getTime() - date1.getTime();
  });
};

export const getSlug = (text: string) => {
  return getLowerCaseNonAccentVietnamese(text.trim().replace(/\s+/g, '-'));
};

export const removeUnusedFields = <T extends any[]>(trees: T): T => {
  return trees.map(tree => ({
    id: tree.id,
    children: removeUnusedFields(tree.children),
  })) as T;
};
