import {SVGAttributes} from 'react';

export interface Base {
  id: string;
  name: string;
}

export interface TreeDataType {
  id: string;
  firstLevel?: boolean;
  children: TreeDataType[];
}

export interface TreeTableForwardRef<T = TreeDataType> {
  handleExpand: (expanded: boolean, record: T) => void;
}

export enum Status {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
}

export interface Pagination {
  total?: number;
  current_page: number;
  per_page: number;
  last_page?: number;
  from: number;
  to?: number;
}

export interface ListPagination<T> {
  data: T[];
  pagination: Pagination;
}

export enum ModalMode {
  EDIT = 'EDIT',
  ADD = 'ADD',
  ADD_CHILD = 'ADD_CHILD',
}

export type CommonObject<K extends string, V = any> = {
  [key in K]?: V;
};

export interface IconProps extends SVGAttributes<SVGSVGElement> {
  size?: number;
}
