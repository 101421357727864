import {Flex, Tag} from 'antd';
import {ColumnsType} from 'antd/es/table';
import Highlighter from 'react-highlight-words';

import {Task, TaskGroup} from '~models';
import {getSlug} from '~utils';

interface GetColumns {
  searchTerm: string;
}

export const getTaskColumns = ({searchTerm}: GetColumns): ColumnsType<Task> => {
  const renderName = (name: string) => (
    <Highlighter searchWords={[searchTerm]} textToHighlight={name} sanitize={getSlug} />
  );

  const renderTaskGroups = (taskGroups: TaskGroup[]) => {
    return (
      <Flex wrap="wrap" gap={6}>
        {taskGroups?.map(tg => (
          <Tag key={tg.id}>{tg.name}</Tag>
        ))}
      </Flex>
    );
  };

  return [
    {
      title: 'Nội dung',
      dataIndex: 'name',
      key: 'name',
      render: renderName,
    },
    {
      title: 'Nhóm lỗi tham chiếu',
      dataIndex: 'groups',
      key: 'groups',
      width: '30%',
      render: renderTaskGroups,
    },
  ];
};
