import {ColumnsType} from 'antd/es/table';
import Highlighter from 'react-highlight-words';

import {CommonObject, Pagination, TaskGroup, User} from '~models';
import {DeleteIcon} from '~assets';
import {getSlug, showConfirmModal} from '~utils';
import {AppDispatch} from '~store';
import {deleteTaskGroupThunk} from '~reducers';
import {AllPermission} from '~variables';

interface GetColumns {
  user?: User;
  dispatch: AppDispatch;
  searchTerm?: string;
  pagination: Pagination;
  taskGroupsLength: number;
  setSearchParams: (values: CommonObject<string>) => void;
  getTaskGroups: () => void;
  allowedPermissions: string[];
}

export const getColumns = ({
  user,
  allowedPermissions,
  searchTerm,
  dispatch,
  pagination,
  taskGroupsLength,
  setSearchParams,
  getTaskGroups,
}: GetColumns): ColumnsType<TaskGroup> => {
  const deleteTaskGroup = (taskGroup: TaskGroup) => {
    showConfirmModal({
      title: 'Xoá nhóm lỗi tham chiếu',
      content: 'Sau khi xác nhận xóa bạn sẽ không thể hoàn tác',
      onOk: () => {
        dispatch(
          deleteTaskGroupThunk({
            id: taskGroup.id,
            callback: () => {
              if (pagination.current_page > 1 && taskGroupsLength === 1) {
                setSearchParams({current_page: 1});
              } else {
                getTaskGroups();
              }
            },
          }),
        );
      },
    });
  };

  const renderEditIcon = (deletable: boolean, taskGroup: TaskGroup) => {
    const canDelete =
      allowedPermissions.includes(AllPermission.Task_groups__Delete) &&
      taskGroup.created_by?.username === user?.username;

    return (
      canDelete &&
      deletable && <DeleteIcon className="delete-icon" onClick={() => deleteTaskGroup(taskGroup)} />
    );
  };

  const renderName = (name: string) => (
    <Highlighter searchWords={[String(searchTerm)]} textToHighlight={name} sanitize={getSlug} />
  );

  return [
    {
      title: 'Nhóm lỗi tham chiếu',
      dataIndex: 'name',
      key: 'name',
      render: renderName,
    },
    {
      title: 'Thao tác',
      dataIndex: 'deletable',
      key: 'deletable',
      width: '10%',
      render: renderEditIcon,
    },
  ];
};
