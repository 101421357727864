import {createAsyncThunk} from '@reduxjs/toolkit';
import {notification} from 'antd';
import {HttpStatusCode} from 'axios';

import {getSearchParamsWindow} from '~utils';
import {addTaskGroupAPI, deleteTaskGroupAPI, getTaskGroupsAPI} from '~services';

import {AddTaskGroupPayload, DeleteTaskGroupPayload, GetTaskGroupsPayload} from './action-models';
import {TaskGroupFilterParams} from '~models';

export const addTaskGroupThunk = createAsyncThunk(
  'TASK_GROUP_SLICE/ADD_TASK_GROUP_THUNK',
  async ({name, callback}: AddTaskGroupPayload) => {
    try {
      const response = await addTaskGroupAPI(name);

      if (response.status === HttpStatusCode.Ok) {
        notification.success({
          message: 'Thêm mới thành công',
          description: 'Thêm mới nhóm lỗi thành công.',
        });

        callback();
      }
    } catch (error: any) {
      notification.error({
        message: 'Thêm mới thất bại',
        description: error.response.data.message,
      });
    }
  },
);

export const getTaskGroupsThunk = createAsyncThunk(
  'TASK_GROUP_SLICE/GET_TASKS_GROUP_THUNK',
  async ({setIsLoading, callback}: GetTaskGroupsPayload) => {
    try {
      setIsLoading(true);
      const searchParams = getSearchParamsWindow<TaskGroupFilterParams>();

      const response = await getTaskGroupsAPI({
        page: searchParams.current_page,
        per_page: searchParams.per_page,
        search: searchParams.search,
      });

      if (response.status === HttpStatusCode.Ok) {
        callback?.(response.data.data);
      }
    } catch (error: any) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  },
);

export const deleteTaskGroupThunk = createAsyncThunk(
  'TASK_GROUP_SLICE/DELETE_TASK_GROUP_THUNK',
  async ({id, callback}: DeleteTaskGroupPayload) => {
    try {
      const response = await deleteTaskGroupAPI(id);

      if (response.status === HttpStatusCode.Ok) {
        notification.success({
          message: 'Xoá thành công',
          description: 'Xoá nhóm lỗi thành công.',
        });

        callback();
      }
    } catch (error: any) {
      notification.error({
        message: 'Xoá thất bại',
        description: error.response.data.message,
      });
    }
  },
);
